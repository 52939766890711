import * as React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { logger } from 'Common/core';
import { AdvancedSubscribeForm as SubscribeForm } from 'Common/components/customer';
import { customer } from '~features';

export default function AdvancedSubscribeForm(props) {
    const subscribeToMailchimp = useAction(customer.actions.subscribeToMailchimp);
    const getMailchimpStatus = useSelector(customer.selectors.getMailchimpStatus);

    const mailchimpSMSRequest = async (formData, url, formRef, frameRef) => {
        if (url && formRef.current && frameRef.current) {
            const newUrl = new URL(url);

            formRef.current.action = newUrl.href;
            formRef.current.method = 'post';
            formRef.current.target = frameRef.current.name;

            Object.entries(formData).forEach(([key, value]) => {
                let input;

                if (key === 'MMERGE5[month]') {
                    input = formRef.current.querySelector('input[name="MMERGE5[month]"]');
                } else if (key === 'MMERGE5[day]') {
                    input = formRef.current.querySelector('input[name="MMERGE5[day]"]');
                } else {
                    input = formRef.current.querySelector(`input[name="${key}"]`);
                }

                if (input) {
                    if (input.type === 'checkbox') {
                        input.checked = !!value;
                    } else {
                        input.value = value;
                    }
                }
            });

            return new Promise((res) => {
                const handleLoad = () => res({ success: true });
                const handleError = () => {
                    logger.warn('Failed to submit Mailchimp form');
                    res({ success: false, error: 'Form.Advanced.Subscription.Failure' });
                };

                frameRef.current.onload = handleLoad;
                frameRef.current.onerror = handleError;

                setTimeout(() => {
                    formRef.current.submit();
                }, 100);
            });
        } else {
            logger.warn('No URL provided for AdvancedSubscribeForm');
        }
        return { success: false };
    };

    return (
        <SubscribeForm
            {...props}
            subscribeToMailchimp={subscribeToMailchimp}
            getMailchimpStatus={getMailchimpStatus}
            mailchimpSMSRequest={mailchimpSMSRequest}
        />
    );
}
